import React, { Component } from 'react';
import Icon from './logo';
import './App.scss';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bg: '#fff',
      fg: '#000',
      faster: 0,
    };
    this.doColors = this.doColors.bind(this);
    document.addEventListener('mousemove', this.doColors);
    document.addEventListener('click', this.doColors);
    document.addEventListener('touchstart', this.doColors);
    document.addEventListener('touchmove', this.doColors);
    document.addEventListener('devicemotion', this.doColors);
    setInterval(() => {
      if (this.state.faster > 0) {
        this.setState({
          faster: this.state.faster - 1,
        });
      }
    }, 100);
  }

  doColors(e) {
    this.setState({
      faster: 10,
      bg: "#"+((1<<24)*Math.random()|0).toString(16),
      fg: "#"+((1<<24)*Math.random()|0).toString(16),
    });
    if (document.selection && document.selection.empty) {
      document.selection.empty();
    } else if(window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header" style={{background: this.state.bg, color: this.state.fg}}>
          {/*<img src={logo} className="App-logo" alt="logo" style={{fill: "#f00"}} />*/}
          <div className={`App-logo ${this.state.faster > 0 ? 'App-logo__faster App-logo__faster-'+this.state.faster : ''}`} title="G">
            <Icon fill={this.state.fg}/>
          </div>
          <p>
            Groozly
          </p>
        </header>
      </div>
    );
  }
}

export default App;
