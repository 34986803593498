import React from 'react';

const Icon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.33 197.33" >
    <g fill={fill}>
      <path d="M98.67,0a98.67,98.67,0,1,0,98.66,98.67A98.66,98.66,0,0,0,98.67,0Zm53.27,143.11q-6.7,6.7-20.4,10.95a91.21,91.21,0,0,1-27.11,4.24Q74.94,158.3,56,142t-19-41.41q0-25.68,19-41.89t49.08-16.2q24.89,0,44.52,9.3V76.46Q128,66,107.42,66q-19.87,0-30.62,10T66.06,101.58q0,14.86,10.74,24.08t28.28,9.21a46.87,46.87,0,0,0,18.66-4V117.66H107.42V97h44.52Z"/>
    </g>
  </svg>
);

export default Icon;
